export const GET_ACCOUNT_PAYMENT = "GET_ACCOUNT_PAYMENT";
export const GET_COMPANY_LIST = "GET_COMPANY_LIST";
export const GET_PAYMENT_CATEGORY_LIST = "GET_PAYMENT_CATEGORY_LIST";
export const GET_ACCOUNT_PAYMENT_DETAIL = "GET_ACCOUNT_PAYMENT_DETAIL";
export const GET_COMPANY_BANK_ACCOUNT_LIST = "GET_COMPANY_BANK_ACCOUNT_LIST";
export const GET_PAYMENT_MODE_CATEGORY_LIST = "GET_PAYMENT_MODE_CATEGORY_LIST";
export const GET_PAYMENT_MODE_LIST = "GET_PAYMENT_MODE_LIST";
export const GET_ACCOUNT_PAYMENT_PAID_LIST = "GET_ACCOUNT_PAYMENT_PAID_LIST";
export const GET_ACCOUNT_PAYMENT_PAID_DETAIL =
  "GET_ACCOUNT_PAYMENT_PAID_DETAIL";
export const GET_PROFITABLE_REPORT_GROUP = "GET_PROFITABLE_REPORT_GROUP";
export const GET_GROUP_EXPORT = "GET_GROUP_EXPORT";
export const GET_PROFITABLE_REPORT_CATEGORY = "GET_PROFITABLE_REPORT_CATEGORY";
export const GET_PROFITABLE_REPORT_CATEGORY_EXPORT =
  "GET_PROFITABLE_REPORT_CATEGORY_EXPORT";
export const GET_PROFITABLE_REPORT_ITEM = "GET_PROFITABLE_REPORT_ITEM";
export const GET_PROJECT_LOAN = "GET_PROJECT_LOAN";
export const GET_PROJECT_LOAN_EXPORT = "GET_PROJECT_LOAN_EXPORT";
export const GET_PROJECT_LOAN_STATUS = "GET_PROJECT_LOAN_STATUS";
export const GET_PROJECT_LOAN_DETAIL = "GET_PROJECT_LOAN_DETAIL";
export const GET_PROJECT_LOAN_PROCESS = "GET_PROJECT_LOAN_PROCESS";
export const GET_PROJECT_LOAN_PROCESS_EXPORT =
  "GET_PROJECT_LOAN_PROCESS_EXPORT";
export const GET_LOAN_PROCESS_FOR_PROCESS_PLAN_ADD =
  "GET_LOAN_PROCESS_FOR_PROCESS_PLAN_ADD";
export const GET_LOAN_PANEL_FOR_PROCESS_PLAN_ADD =
  "GET_LOAN_PANEL_FOR_PROCESS_PLAN_ADD";
export const GET_PROJECT_MORTGAGE = "GET_PROJECT_MORTGAGE";
export const GET_PROJECT_MORTGAGE_EXPORT = "GET_PROJECT_MORTGAGE_EXPORT";
export const GET_PROJECT_MORTGAGE_DETAILS = "GET_PROJECT_MORTGAGE_DETAILS";
export const GET_LOAN_PROCESS_STATUS = "GET_LOAN_PROCESS_STATUS";
export const GET_LOAN_PROCESS_STATUS_EXPORT = "GET_LOAN_PROCESS_STATUS_EXPORT";
export const GET_LOAN_PROCESS_PLAN_DETAIL = "GET_LOAN_PROCESS_PLAN_DETAIL";
export const GET_PROJECT_MORTGAGE_ELIGIBLE_FILE =
  "GET_PROJECT_MORTGAGE_ELIGIBLE_FILE";
export const GET_LOAN_PROCESS_STATUS_FOR_UPDATE =
  "GET_LOAN_PROCESS_STATUS_FOR_UPDATE";
export const GET_PROJECT_LOAN_FILTER_PROJECT =
  "GET_PROJECT_LOAN_FILTER_PROJECT";
export const GET_PROJECT_LOAN_FILTER_BANK = "GET_PROJECT_LOAN_FILTER_BANK";
export const GET_LOAN_PROCESS_PLAN_FILTER_PROJECT =
  "GET_LOAN_PROCESS_PLAN_FILTER_PROJECT";
export const GET_LOAN_PROCESS_PLAN_FILTER_BANK =
  "GET_LOAN_PROCESS_PLAN_FILTER_BANK";
export const GET_LOAN_PROCESS_PLAN_FILTER_PANEL =
  "GET_LOAN_PROCESS_PLAN_FILTER_PANEL";
export const GET_LOAN_PROCESS_PLAN_FILTER_PROCESS =
  "GET_LOAN_PROCESS_PLAN_FILTER_PROCESS";
export const GET_LOAN_PROCESS_PLAN_FOR_QUERY_CREATE =
  "GET_LOAN_PROCESS_PLAN_FOR_QUERY_CREATE";
export const GET_LOAN_PROCESS_PLAN_QUERY = "GET_LOAN_PROCESS_PLAN_QUERY";
export const GET_LOAN_PROCESS_PLAN_QUERY_EXPORT =
  "GET_LOAN_PROCESS_PLAN_QUERY_EXPORT";
export const GET_LOAN_PROCESS_PLAN_QUERY_DETAIL =
  "GET_LOAN_PROCESS_PLAN_QUERY_DETAIL";
export const GET_LOAN_PROCESS_PLAN_QUERY_FILTER_PROJECT =
  "GET_LOAN_PROCESS_PLAN_QUERY_FILTER_PROJECT";
export const GET_LOAN_PROCESS_PLAN_QUERY_FILTER_BANK =
  "GET_LOAN_PROCESS_PLAN_QUERY_FILTER_BANK";
export const GET_LOAN_PROCESS_PLAN_QUERY_FILTER_PANEL =
  "GET_LOAN_PROCESS_PLAN_QUERY_FILTER_PANEL";
export const GET_LOAN_PROCESS_PLAN_QUERY_FILTER_RESPONSIBLE =
  "GET_LOAN_PROCESS_PLAN_QUERY_FILTER_RESPONSIBLE";
export const GET_LOAN_PROCESS_PLAN_QUERY_FILTER_ACCOUNTABLE =
  "GET_LOAN_PROCESS_PLAN_QUERY_FILTER_ACCOUNTABLE";
export const GET_LOAN_PROCESS_PLAN_QUERY_FILTER_PROCESS =
  "GET_LOAN_PROCESS_PLAN_QUERY_FILTER_PROCESS";
export const GET_LOAN_PROCESS_PLAN_FILTER_RESPONSIBLE =
  "GET_LOAN_PROCESS_PLAN_FILTER_RESPONSIBLE";
export const GET_LOAN_PROCESS_PLAN_FILTER_ACCOUNTABLE =
  "GET_LOAN_PROCESS_PLAN_FILTER_ACCOUNTABLE";
export const GET_LOAN_PROCESS_PLAN_QUERY_TEMPLATE =
  "GET_LOAN_PROCESS_PLAN_QUERY_TEMPLATE";
export const GET_LOAN_PROCESS_PLAN_QUERY_REPORT =
  "GET_LOAN_PROCESS_PLAN_QUERY_REPORT";
// Vendor Nithish
export const GET_VENDOR = "GET_VENDOR";
export const GET_CONSTITUTION_OF_FIRM = "GET_CONSTITUTION_OF_FIRM";
export const GET_VENDOR_DOCUMENTS = "GET_VENDOR_DOCUMENTS";
export const GET_VENDOR_DOCUMENT_TYPE = "GET_VENDOR_DOCUMENT_TYPE";
export const GET_VENDOR_SERVICE = "GET_VENDOR_SERVICE";
export const GET_VENDOR_SERVICE_MASTER = "GET_VENDOR_SERVICE_MASTER";
export const GET_VENDOR_CONTACT = "GET_VENDOR_CONTACT";
export const GET_VENDOR_ACCOUNT = "GET_VENDOR_ACCOUNT";
export const GET_VENDOR_ACCOUNT_DOCUMENT_TYPE =
  "GET_VENDOR_ACCOUNT_DOCUMENT_TYPE";
export const GET_VENDOR_ADDRESS = "GET_VENDOR_ADDRESS";
export const GET_STATE = "GET_STATE";
export const GET_VENDOR_ACTION_HISTORY = "GET_VENDOR_ACTION_HISTORY";
export const UPDATE_VENDOR = "UPDATE_VENDOR";
export const GET_VENDOR_DETAILS = "GET_VENDOR_DETAILS";
export const GET_ACCOUNT_PAYMENT_FILTER_COMPANY =
  "GET_ACCOUNT_PAYMENT_FILTER_COMPANY";
export const GET_ACCOUNT_PAYMENT_FILTER_PROJECT =
  "GET_ACCOUNT_PAYMENT_FILTER_PROJECT";
export const GET_ACCOUNT_PAYMENT_FILTER_REQUESTED_BY =
  "GET_ACCOUNT_PAYMENT_FILTER_REQUESTED_BY";
export const GET_ACCOUNT_PAYMENT_FILTER_CATEGORY =
  "GET_ACCOUNT_PAYMENT_FILTER_CATEGORY";
export const GET_VENDOR_CHANGE_REQUEST = "GET_VENDOR_CHANGE_REQUEST";
export const GET_VENDOR_CHANGE_REQUEST_DETAILS =
  "GET_VENDOR_CHANGE_REQUEST_DETAILS";
export const GET_CIVIL_PROJECT_BILL_ACCOUNT = "GET_CIVIL_PROJECT_BILL_ACCOUNT";
export const GET_CIVIL_PROJECT_BILL_ACCOUNT_EXPORT =
  "GET_CIVIL_PROJECT_BILL_ACCOUNT_EXPORT";
export const GET_VENDOR_EXPORT = "GET_VENDOR_EXPORT";
export const GET_CHANNEL_PARTNER = "GET_CHANNEL_PARTNER";
export const GET_CHANNEL_PARTNER_EXPORT = "GET_CHANNEL_PARTNER_EXPORT";
export const GET_VENDOR_DETAILS_PRINT = "GET_VENDOR_DETAILS_PRINT";
//govt fees
export const GET_GOVT_FEES = "GET_GOVT_FEES";
export const GET_GOVT_FEES_PROCESS_PLAN = "GET_GOVT_FEES_PROCESS_PLAN";
export const GET_GOVERNMENT_DETAIL = "GET_GOVERNMENT_DETAIL";
export const GET_GOVT_FEES_PROCESS_ITEM = "GET_GOVT_FEES_PROCESS_ITEM";
export const GET_GOVT_FEES_ITEM = "GET_GOVT_FEES_ITEM";
export const GET_GOVT_FEES_PROJECT_FILTER = "GET_GOVT_FEES_PROJECT_FILTER";
export const GET_GOVT_FEES_PROCESS_FILTER = "GET_GOVT_FEES_PROCESS_FILTER";
export const GET_GOVT_FEES_FILTER_COMPANY = "GET_GOVT_FEES_FILTER_COMPANY";
export const GET_GOVT_FEE_SURVEY_NUMBER = "GET_GOVT_FEE_SURVEY_NUMBER";
export const GET_RETENTION_PERCENTAGE = "GET_RETENTION_PERCENTAGE";
export const GET_RETENTION_PERCENTAGE_FILTER_BILL_CATEGORY =
  "GET_RETENTION_PERCENTAGE_FILTER_BILL_CATEGORY";
export const GET_RETENTION_PERCENTAGE_EXPORT =
  "GET_RETENTION_PERCENTAGE_EXPORT";
export const GET_BILL_CATEGORY = "GET_BILL_CATEGORY";
export const GET_GOVT_FEES_TASK_PLAN = "GET_GOVT_FEES_TASK_PLAN";
//VendorLager
export const GET_VENDOR_LADGER = "GET_VENDOR_LADGER";
export const GET_VENDOR_LADGER_EXPORT = "GET_VENDOR_LADGER_EXPORT";
export const GET_VENDOR_LADGER_DETAILS = "GET_VENDOR_LADGER_DETAILS";
export const GET_VENDOR_FOR_LEDGER = "GET_VENDOR_FOR_LEDGER";
export const VENDOR_LADGER_ITEM = "VENDOR_LADGER_ITEM";
export const GET_VENDOR_LADGER_ITEM_EXPORT = "GET_VENDOR_LADGER_ITEM_EXPORT";
export const GET_VENDOR_LADGER_ITEM_PRINT = "GET_VENDOR_LADGER_ITEM_PRINT";
export const GET_VENDOR_LADGER_FILTER_VENDOR =
  "GET_VENDOR_LADGER_FILTER_VENDOR";
export const GET_VENDOR_LADGER_FILTER_COMPANY =
  "GET_VENDOR_LADGER_FILTER_COMPANY";
export const GET_VENDOR_LADGER_ITEM_FILTER_VENDOR =
  "GET_VENDOR_LADGER_ITEM_FILTER_VENDOR";
export const GET_VENDOR_LADGER_ITEM_FILTER_COMPANY =
  "GET_VENDOR_LADGER_ITEM_FILTER_COMPANY";
export const GET_VENDOR_LADGER_ITEM_FILTER_PROJECT =
  "GET_VENDOR_LADGER_ITEM_FILTER_PROJECT";
export const GET_VENDOR_LADGER_ITEM_FILTER_CATEGORY =
  "GET_VENDOR_LADGER_ITEM_FILTER_CATEGORY";
export const GET_PAYMENT_REQUEST = "GET_PAYMENT_REQUEST";
export const GET_PAYMENT_REQUEST_FILTER_PROJECT =
  "GET_PAYMENT_REQUEST_FILTER_PROJECT";
export const GET_PAYMENT_REQUEST_FILTER_VENDOR =
  "GET_PAYMENT_REQUEST_FILTER_VENDOR";
export const GET_PAYMENT_REQUEST_FILTER_CATEGORY =
  "GET_PAYMENT_REQUEST_FILTER_CATEGORY";
export const GET_PAYMENT_REQUEST_FILTER_COMPANY =
  "GET_PAYMENT_REQUEST_FILTER_COMPANY";
export const GET_PAYMENT_REQUEST_EXPORT = "GET_PAYMENT_REQUEST_EXPORT";
export const GET_PAYMENT_REQUEST_DETAIL = "GET_PAYMENT_REQUEST_DETAIL";
export const GET_VENDOR_ACCOUNT_FOR_PAYMENT_REQUEST =
  "GET_VENDOR_ACCOUNT_FOR_PAYMENT_REQUEST";
export const GET_PAYMENT_REQUEST_APPROVAL = "GET_PAYMENT_REQUEST_APPROVAL";
export const GET_PAYMENT_REQUEST_APPROVAL_EXPORT =
  "GET_PAYMENT_REQUEST_APPROVAL_EXPORT";
export const GET_CATEGORY_FOR_PAYMENT_REQUEST =
  "GET_CATEGORY_FOR_PAYMENT_REQUEST";
export const GET_VENDOR_PAYMENT_INFO_FOR_PAYMENT_REQUEST =
  "GET_VENDOR_PAYMENT_INFO_FOR_PAYMENT_REQUEST";
export const GET_VENDOR_PO_WO_FOR_PAYMENT_REQUEST =
  "GET_VENDOR_PO_WO_FOR_PAYMENT_REQUEST";
export const GET_COMPANY_FOR_PAYMENT_REQUEST =
  "GET_COMPANY_FOR_PAYMENT_REQUEST";
export const GET_PROJECT_FOR_PAYMENT_REQUEST =
  "GET_PROJECT_FOR_PAYMENT_REQUEST";
export const GET_VENDOR_FOR_PAYMENT_REQUEST = "GET_VENDOR_FOR_PAYMENT_REQUEST";
